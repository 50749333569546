const { THREE } = window.armor.animations;

const animationConfig = {
  getUniforms() {
    return {
      /* eslint-disable camelcase */
      u_pointsize: { value: 1.5 },

      // wave 1
      u_noise_freq_1: { value: 3.0 },
      u_noise_amp_1: { value: 0.2 },
      u_spd_modifier_1: { value: 0.25 },

      // wave 2
      u_noise_freq_2: { value: 2.0 },
      u_noise_amp_2: { value: 0.3 },
      u_spd_modifier_2: { value: 0.5 },
      /* eslint-enable camelcase */
    };
  },
  vertexShader() {
    return `
    #define PI 3.14159265359

    uniform float u_time;
    uniform float u_pointsize;
    uniform float u_noise_amp_1;
    uniform float u_noise_freq_1;
    uniform float u_spd_modifier_1;
    uniform float u_noise_amp_2;
    uniform float u_noise_freq_2;
    uniform float u_spd_modifier_2;

    float random(in vec2 st) {
      return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }

    float noise(in vec2 st) {
      vec2 i = floor(st);
      vec2 f = fract(st);

      float a = random(i);
      float b = random(i + vec2(1.0, 0.0));
      float c = random(i + vec2(0.0, 1.0));
      float d = random(i + vec2(1.0, 1.0));

      vec2 u = f*f*(3.0-2.0*f);

      return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    mat2 rotate2d(float angle) {
      return mat2(cos(angle), -sin(angle), sin(angle), cos(angle));
    }

    void main() {
      gl_PointSize = u_pointsize;

      vec3 pos = position;
      pos.z += noise(pos.xy * u_noise_freq_1 + u_time * u_spd_modifier_1) * u_noise_amp_1;
      pos.z += noise(rotate2d(PI / 4.) * pos.yx * u_noise_freq_2 - u_time * u_spd_modifier_2 * 0.6) * u_noise_amp_2;

      vec4 mvm = modelViewMatrix * vec4(pos, 1.0);
      gl_Position = projectionMatrix * mvm;
    }
    `;
  },
  fragmentShader() {
    return `
    void main() {
      gl_FragColor = vec4(0.4,0.4,0.4,0.75);
    }
    `;
  },
  updateScene() {
    this.controls.update();
  },
};

document.addEventListener('DOMContentLoaded', () => {
  function heightDelegate() {
    const height = window.innerHeight * 0.8;
    if (height < 768) {
      return 768;
    }
    return height;
  }

  const app = window.armor.animations.createAnimation(
    'animation-container',
    animationConfig,
    window.armor.animations.defaultWidthDelegate,
    heightDelegate,
    { alpha: true },
  );

  app.camera.position.set(0, 1, 2);
  app.camera.updateProjectionMatrix();

  app.controls.enabled = false;
  app.controls.autoRotate = false;
  app.controls.autoRotateSpeed = 0.75;

  window.armor.themes.registerListener(() => {
    app.camera.updateProjectionMatrix();
  });

  // Mesh
  const geometry = new THREE.PlaneGeometry(5, 6, 300, 200);
  const material = new THREE.ShaderMaterial({
    uniforms: app.uniforms,
    vertexShader: app.vertexShader(),
    fragmentShader: app.fragmentShader(),
  });

  const mesh = new THREE.Points(geometry, material);
  app.scene.add(mesh);
  mesh.position.set(0, 0, 1);

  mesh.rotation.x = Math.PI / 2.8;
  mesh.rotation.y = 0;

  const mediaQuery = window.matchMedia('(min-width: 600px)');
  const hiddenMesh = document.querySelector('#animation-container canvas');

  function handleViewportChange(e) {
    if (e.matches) {
      app.animate();
      hiddenMesh.classList.add('d-block');
      hiddenMesh.classList.remove('d-none');
    }
    else {
      hiddenMesh.classList.remove('d-block');
      hiddenMesh.classList.add('d-none');
    }
  }
  mediaQuery.addListener(handleViewportChange);
  handleViewportChange(mediaQuery);

});
